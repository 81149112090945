// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-newsletter-archive-js": () => import("./../../../src/pages/newsletter/archive.js" /* webpackChunkName: "component---src-pages-newsletter-archive-js" */),
  "component---src-pages-newsletter-expired-js": () => import("./../../../src/pages/newsletter/expired.js" /* webpackChunkName: "component---src-pages-newsletter-expired-js" */),
  "component---src-pages-newsletter-index-js": () => import("./../../../src/pages/newsletter/index.js" /* webpackChunkName: "component---src-pages-newsletter-index-js" */),
  "component---src-pages-newsletter-unsubscribed-js": () => import("./../../../src/pages/newsletter/unsubscribed.js" /* webpackChunkName: "component---src-pages-newsletter-unsubscribed-js" */),
  "component---src-pages-newsletter-welcome-js": () => import("./../../../src/pages/newsletter/welcome.js" /* webpackChunkName: "component---src-pages-newsletter-welcome-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/Articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/Products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/Tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

